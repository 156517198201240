import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import breakpoints from 'helpers/breakpoints'
import striptags from 'striptags'

const Line = styled.span`
  ${props => breakpoints.greaterThan(props.breakpoint)`
    display: block;
  `}
`

const LineBreaks = ({ text, keyId, breakpoint }) => {
  const lines = text.split('\n')
  return (
    <>
      {lines.map((line, index) => {
        return (
          <Line
            breakpoint={breakpoint}
            key={`${keyId}-${index}`}
            dangerouslySetInnerHTML={{
              __html: striptags(line, ['span', 'em']) + ' ',
            }}
          />
        )
      })}
    </>
  )
}
LineBreaks.defaultProps = {
  breakpoint: 'md',
}

LineBreaks.propTypes = {
  text: PropTypes.string.isRequired,
  keyId: PropTypes.string.isRequired,
  breakpoint: PropTypes.string,
}

export default memo(LineBreaks)
