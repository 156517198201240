import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { bullet } from 'constants/svg'

import Box from 'components/atoms/Box'

const Text = styled(Box)`
  p {
    &:not(:first-child) {
      margin: 3rem 0;
    }
  }

  ul {
    margin: 0;
    list-style: none;
    li {
      position: relative;
      padding-left: 2rem;
      margin-bottom: 1rem;
      font-weight: ${props => props.theme.fontWeights.medium};
      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin: 0;
      }

      &:before {
        position: absolute;
        top: 1.4rem;
        left: 0;
        content: '';
        display: block;
        width: 7px;
        height: 1px;
        background: ${props => props.theme.colors.primary};
      }
    }
  }
`

const Markdown = ({ html, ...props }) => (
  <Text
    {...props}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
)

Markdown.propTypes = {
  html: PropTypes.string.isRequired,
}

export default memo(Markdown)
