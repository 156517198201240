import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'
import theme from 'constants/theme'

import Section from 'components/atoms/layout/Section'
import Heading from 'components/atoms/headings/Heading'
import Box from 'components/atoms/Box'
import SectionJoin from 'components/atoms/dividers/SectionJoin'
import Markdown from 'components/atoms/text/Markdown'
import ResponsivePlx from 'components/atoms/plx/ResponsivePlx'
import ScrollRevealText from 'components/atoms/text/ScrollRevealText'
import TextPlx from 'components/atoms/plx/TextPlx'

const StyledMarkdown = styled(Markdown)`
  color: #b9b9b9;

  strong,
  li {
    color: white;
  }
`

const Image = styled(Img)`
  > div {
    padding-bottom: 85% !important;
  }

  ${breakpoints.greaterThan('sm')`
    > div {
      padding-bottom: 65% !important;
    }
  `}

  ${breakpoints.greaterThan('md')`

    > div {
      padding-bottom: 150% !important;
    }
  `}

  ${breakpoints.greaterThan('lg')`

    > div {
      padding-bottom: 110% !important;
    }
  `}
`

const Details2 = ({ data }) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      startOffset: '-20vh',
      endOffset: '200vh',
      properties: [
        {
          startValue: -10,
          endValue: -30,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ]

  return (
    <Section bg="darkBg" color="white">
      <Container maxWidth={false} className="foreground">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Box ml={{ xs: -2, sm: -3, md: -4 }} mt={{ xs: -5, md: 0 }}>
              <ResponsivePlx breakpoint="md" parallaxData={parallaxData}>
                <Image fluid={data.detailsSection2Image.fluid} />
              </ResponsivePlx>
            </Box>
          </Grid>
          <Grid item lg={1} implementation="css" mdDown component={Hidden} />
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <Box
              pl={{ md: 1, lg: 0 }}
              pt={{ xs: 3, lg: 6, xl: 8 }}
              pb={{ xs: 6, sm: 8, md: 10 }}
            >
              <TextPlx>
                <Heading $as="h2" headingSize="h2" color="white" mb={{ xs: 3 }}>
                  {data.detailsSection2Heading}
                </Heading>
              </TextPlx>

              <ScrollRevealText
                components={data.detailsSection2TextComponents}
                keyId="services-details1b"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <SectionJoin />
    </Section>
  )
}

Details2.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Details2)
