import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Box from 'components/atoms/Box'
import HeadingDivider from 'components/atoms/dividers/HeadingDivider'
import Heading from 'components/atoms/headings/Heading'

const Container = styled(Box)`
  &.light {
    color: white;
  }
`

const SectionTitle = ({ children, light, containerProps, ...props }) => {
  let containerClass = ''
  if (light) {
    containerClass = 'light'
  }

  return (
    <Container
      className={containerClass}
      textAlign="center"
      mb={6}
      {...containerProps}
    >
      <Heading as="h2" textAlign="center" {...props}>
        {children}
      </Heading>
      <HeadingDivider mt={2} mx="auto" />
    </Container>
  )
}

SectionTitle.defaultProps = {
  headingSize: 'h2',
}

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  headingSize: PropTypes.string,
  light: PropTypes.bool,
  headingClass: PropTypes.string,
  containerProps: PropTypes.object,
}

export default memo(SectionTitle)
