import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'
import Hidden from '@material-ui/core/Hidden'

import breakpoints from 'helpers/breakpoints'

import LineBreaks from 'components/atoms/text/LineBreaks'
import Section from 'components/atoms/layout/Section'
import SmallHeading from 'components/atoms/headings/SmallHeading'
import Heading from 'components/atoms/headings/Heading'
import Box from 'components/atoms/Box'
import Markdown from 'components/atoms/text/Markdown'
import ResponsivePlx from 'components/atoms/plx/ResponsivePlx'
import ScrollRevealText from 'components/atoms/text/ScrollRevealText'
import TextPlx from 'components/atoms/plx/TextPlx'

const ContentGrid1 = styled(Grid)`
  align-items: center;

  ${breakpoints.lessThan('sm')`
    .image {
      margin-left: -20px;
    }
  `}
  ${breakpoints.lessThan('md')`

    .image {
      margin-left: -30px;
    }
  `}
`
const ContentGrid2 = styled(Grid)`
  align-items: center;

  ${breakpoints.lessThan('md')`
    .content {
      order: 2;
    }
    .image {
      order: 1;
      transform: translateX(30px);
    }
  `}
  ${breakpoints.lessThan('sm')`
    .image {
      transform: translateX(20px);
    }
  `}
`

const Image1 = styled(Img)`
  > div {
    padding-bottom: 85% !important;
  }

  ${breakpoints.greaterThan('sm')`
    > div {
      padding-bottom: 65% !important;
    }
  `}

  ${breakpoints.greaterThan('md')`

    > div {
      padding-bottom: 150% !important;
    }
  `}

  ${breakpoints.greaterThan('lg')`

    > div {
      padding-bottom: 140% !important;
    }
  `}
`

const Image3 = styled(Img)`
  > div {
    padding-bottom: 90% !important;
  }
`

const Details1 = ({ data }) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 20,
          endValue: -20,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ]

  const parallaxData2 = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 40,
          endValue: 0,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ]

  return (
    <Section pt={{ xs: 13, xl: 15 }}>
      <Container maxWidth="xl" className="foreground">
        <Hidden mdUp implementation="css">
          <TextPlx>
            <Heading $as="h2" headingSize="h2" mb={3}>
              {data.detailsSectionHeading}
            </Heading>
          </TextPlx>
        </Hidden>
        <ContentGrid1 container spacing={3}>
          <Grid item xs={12} md={6} lg={6} xl={5} className="image">
            <ResponsivePlx breakpoint="md" parallaxData={parallaxData}>
              <Image1 fluid={data.detailsSectionImage1.fluid} />
            </ResponsivePlx>
          </Grid>
          <Grid
            item
            lg={1}
            xl={2}
            implementation="css"
            mdDown
            component={Hidden}
          />
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <Hidden smDown implementation="css">
              <TextPlx>
                <Heading $as="h2" headingSize="h2" mb={3}>
                  {data.detailsSectionHeading}
                </Heading>
              </TextPlx>
            </Hidden>
            <ScrollRevealText
              components={data.detailsSectionText1Components}
              keyId="services-details1"
            />
          </Grid>
        </ContentGrid1>
        <ContentGrid2 container spacing={3}>
          <Grid item xs={12} md={6} lg={6} xl={5} className="content">
            <Box px={{ xl: 6 }}>
              <ScrollRevealText
                components={data.detailsSectionText2Components}
                keyId="services-details1"
                reverse
              />

              <TextPlx reverse>
                <SmallHeading leftBorder noDivider mt={4}>
                  <LineBreaks
                    text={
                      data.detailsSectionText2FooterNode.childMarkdownRemark
                        .html
                    }
                    keyId="services-details-1"
                  />
                </SmallHeading>
              </TextPlx>
            </Box>
          </Grid>
          <Grid
            item
            lg={1}
            xl={2}
            implementation="css"
            mdDown
            component={Hidden}
          />

          <Grid item xs={12} md={6} lg={5} xl={4} className="image">
            <ResponsivePlx breakpoint="md" parallaxData={parallaxData}>
              <Image1 fluid={data.detailsSectionImage2.fluid} />
            </ResponsivePlx>
          </Grid>
        </ContentGrid2>
        <Box mt={{ xl: '-60px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6} xl={5}>
              <Box
                ml={{ xs: -2, sm: 0 }}
                mb={{ xs: -5, sm: 0 }}
                width={{ xs: '80%', sm: '100%' }}
                mt={{ xs: 4 }}
              >
                <ResponsivePlx breakpoint="md" parallaxData={parallaxData2}>
                  <Image3 fluid={data.detailsSectionImage3.fluid} />
                </ResponsivePlx>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Section>
  )
}

Details1.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Details1)
