import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Box from 'components/atoms/Box'
import TextPlx from 'components/atoms/plx/TextPlx'
import Markdown from 'components/atoms/text/Markdown'

const ScrollRevealText = ({ components, keyId, reverse, ...props }) => {
  return (
    <>
      {components.map((component, index) => {
        let shouldPlx = false
        if (
          component.model.apiKey === 'paragraph' ||
          component.model.apiKey === 'formattable_paragraph'
        ) {
          shouldPlx = true
        }
        return (
          <Box key={`${keyId}-${index}`} {...props}>
            {shouldPlx && (
              <TextPlx reverse={reverse}>
                <Markdown
                  html={component.paragraphNode.childMarkdownRemark.html}
                />
              </TextPlx>
            )}
          </Box>
        )
      })}
    </>
  )
}

ScrollRevealText.propTypes = {
  components: PropTypes.array.isRequired,
  keyId: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
}

export default memo(ScrollRevealText)
