import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from 'components/atoms/Box'

const Frame = styled(Box)`
  position: relative;

    .gatsby-image-wrapper {
      position: relative;
      overflow: visible !important;

      img {
        border-radius: 50%;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.colors.primary};
      }
   

    }
  }
`

const RoundFrame = ({ children, ...props }) => (
  <Frame {...props}>
    <div className="inner">{children}</div>
  </Frame>
)

RoundFrame.propTypes = {
  children: PropTypes.object.isRequired,
}

export default memo(RoundFrame)
