import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import is from 'styled-is'
import {
  Swiper,
  Pagination,
  EffectFade,
  Autoplay,
} from 'swiper/js/swiper.esm.js'
import ReactIdSwiper from '@danteblitz/react-id-swiper/lib/ReactIdSwiper.custom'
import 'swiper/css/swiper.css'

const Container = styled.div`
  ${is('fullHeight')`
    &,
    .swiper-container,
    .swiper-wrapper {
      height: 100%;
    }
  `};

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-pagination {
    position: relative;
    margin-top: 60px;
  }

  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    margin: 0 10px !important;
    background: rgba(255, 255, 255, 0);
    border: 1px solid white;
    opacity: 1;
    border-radius: 0;
    transform: rotate(45deg);
    transition: background-color ${props => props.theme.transitions.default};

    &.swiper-pagination-bullet-active {
      background-color: rgba(255, 255, 255, 1);
    }
  }
`

const FadeSwiper = ({ children, ...props }) => {
  const params = {
    Swiper,
    modules: [Pagination, EffectFade, Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    loop: true,
    speed: 600,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    slideChangeTransitionStart: () => {
      let $wrapperEl = this.swiper.$wrapperEl
      let params = this.swiper.params
      $wrapperEl
        .children('.' + params.slideClass + '.' + params.slideDuplicateClass)
        .each(function() {
          let idx = this.getAttribute('data-swiper-slide-index')
          this.innerHTML = $wrapperEl
            .children(
              '.' +
                params.slideClass +
                '[data-swiper-slide-index="' +
                idx +
                '"]:not(.' +
                params.slideDuplicateClass +
                ')'
            )
            .html()
        })
    },
    slideChangeTransitionEnd: () => {
      this.swiper.slideToLoop(this.swiper.realIndex, 0, false)
    },
  }

  return (
    <Container {...props}>
      <ReactIdSwiper {...params}>{children}</ReactIdSwiper>
    </Container>
  )
}

FadeSwiper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(FadeSwiper)
