import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'

const TextPlx = ({ children, reverse, ...props }) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '30vh',
      properties: [
        {
          startValue: reverse ? -50 : 50,
          endValue: 0,
          property: 'translateX',
          unit: 'px',
        },
        {
          startValue: 0,
          endValue: 1,
          property: 'opacity',
        },
      ],
    },
  ]

  return (
    <Plx parallaxData={parallaxData} {...props}>
      {children}
    </Plx>
  )
}

TextPlx.propTypes = {
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
}

export default memo(TextPlx)
