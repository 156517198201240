import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Flex from 'components/atoms/Flex'
import SmallText from 'components/atoms/text/SmallText'

const ScrollDivider = styled.div`
  width: 1px;
  height: 60px;
  opacity: 0.8;
  margin-bottom: 20px;
  position: relative;
  background: rgba(255, 255, 255, 0.4);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    display: block;
    width: 1px;
    height: 50%;
    background: white;
    margin-left: 1px;
  }
`

const ScrollText = styled(SmallText)`
  letter-spacing: 0.3rem;
  text-align: center;
`

const BottomFlex = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const Explore = ({ children }) => {
  return (
    <BottomFlex pb={{ xs: 3, lg: 4 }} flexDirection="column" center>
      <ScrollDivider />
      <ScrollText>{children}</ScrollText>
    </BottomFlex>
  )
}

Explore.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(Explore)
