import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Plx from 'react-plx'
import { withWindowSizeListener } from 'react-window-size-listener'

import breakpointObj from 'constants/breakpoints'

const StyledPlx = styled(Plx)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
`

const BackgroundPlx = ({ parallaxData, breakpoint, windowSize, ...props }) => {
  return (
    <StyledPlx
      parallaxData={parallaxData}
      disabled={
        typeof window !== 'undefined' &&
        windowSize.windowWidth < breakpointObj[breakpoint]
          ? true
          : false
      }
      {...props}
    />
  )
}

BackgroundPlx.propTypes = {
  parallaxData: PropTypes.array.isRequired,
  windowSize: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
}

export default typeof window !== 'undefined'
  ? memo(withWindowSizeListener(BackgroundPlx))
  : BackgroundPlx
