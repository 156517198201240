import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Swiper, EffectFade, Autoplay } from 'swiper/js/swiper.esm.js'
import ReactIdSwiper from '@danteblitz/react-id-swiper/lib/ReactIdSwiper.custom'
import 'swiper/css/swiper.css'

const Container = styled.div`
  &,
  .swiper-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-pagination {
    position: relative;
    margin-top: 60px;
  }

  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    margin: 0 10px !important;
    background: rgba(255, 255, 255, 0);
    border: 1px solid white;
    opacity: 1;
    border-radius: 0;
    transform: rotate(45deg);
    transition: background-color ${(props) => props.theme.transitions.default};

    &.swiper-pagination-bullet-active {
      background-color: rgba(255, 255, 255, 1);
    }
  }
`

const HeroSwiper = ({ children }) => {
  const params = {
    Swiper,
    modules: [EffectFade, Autoplay],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    preloadImages: false,
    speed: 600,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    fadeEffect: {
      crossFade: true,
    },
  }

  return (
    <Container>
      <ReactIdSwiper {...params}>{children}</ReactIdSwiper>
    </Container>
  )
}

HeroSwiper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(HeroSwiper)
