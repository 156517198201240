import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import { withWindowSizeListener } from 'react-window-size-listener'
import cloneDeep from 'lodash/cloneDeep'
import breakpointObj from 'constants/breakpoints'

const ResponsivePlx = ({ windowSize, breakpoint, parallaxData, ...props }) => {
  let responsiveData = cloneDeep(parallaxData)

  responsiveData.map((item, index) => {
    item.properties.map((property, i) => {
      if (
        typeof window !== 'undefined' &&
        windowSize.windowWidth < breakpointObj.xl
      ) {
        property.startValue = Math.floor(property.startValue * 0.6)
        property.endValue = Math.floor(property.endValue * 0.6)
      } else {
        property.startValue = parallaxData[index].properties[i].startValue
        property.endValue = parallaxData[index].properties[i].endValue
      }
    })
  })
  return (
    <Plx
      parallaxData={responsiveData}
      disabled={
        typeof window !== 'undefined' &&
        windowSize.windowWidth < breakpointObj[breakpoint]
          ? true
          : false
      }
      {...props}
    />
  )
}

ResponsivePlx.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  windowSize: PropTypes.object.isRequired,
  parallaxData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default typeof window !== 'undefined'
  ? memo(withWindowSizeListener(ResponsivePlx))
  : ResponsivePlx
