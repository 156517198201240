import React, { memo } from 'react'
import styled from 'styled-components'
import Div100vhBase from 'react-div-100vh'

const Div = styled(Div100vhBase)`
  height: 1px;
  min-height: 800px;
`

const Div100vh = props => {
  return <Div {...props} />
}

export default memo(Div100vh)
