import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from 'components/organisms/global/Layout'
import ImageHero from 'components/molecules/hero/ImageHero'
import Heading from 'components/atoms/headings/Heading'
import Intro from 'components/organisms/_page-specific/services/Intro'
import Details1 from 'components/organisms/_page-specific/services/Details1'
import Details2 from 'components/organisms/_page-specific/services/Details2'
import Quote from 'components/organisms/_page-specific/services/Quote'
import DownloadSection from 'components/organisms/sections/DownloadSection'

const Services = ({ data }) => {
  const page = data.datoCmsServiceLanding

  const metaObj = {
    title: page.seoMetadata.title,
    description: page.seoMetadata.description,
    image: {
      src: page.metaImage.fixed.src,
      width: page.metaImage.fixed.width,
      height: page.metaImage.fixed.height,
    },
  }

  return (
    <Layout
      headerProps={{
        navImage: page.mainNavigationImage,
      }}
      metadata={metaObj}
    >
      <ImageHero images={page.heroSectionBackgroundImage}>
        <Heading as="h1" headingSize="jumbo" color="white">
          {page.heroSectionHeading}
        </Heading>
      </ImageHero>
      <Intro data={page} />
      <Details1 data={page} />
      <Quote bgImage={page.quotesSectionBackgroundImage} quote={page.quote} />
      <Details2 data={page} />
      <DownloadSection
        heading={page.brochureSectionHeading}
        text={page.brochureSectionText}
        fileUrl={page.brochureSectionPdfBrochure.url}
        buttonText={page.brochureSectionButtonLabel}
      />
    </Layout>
  )
}

Services.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Services

export const pageQuery = graphql`
  {
    datoCmsServiceLanding {
      seoMetadata {
        description
        title
      }
      mainNavigationImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      brochureSectionHeading
      brochureSectionButtonLabel
      brochureSectionPdfBrochure {
        url
      }
      brochureSectionText
      detailsSectionHeading
      detailsSectionImage1 {
        fluid(
          maxWidth: 600
          imgixParams: { w: "600", fm: "jpg", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      detailsSection2Heading
      detailsSection2TextComponents {
        ... on DatoCmsFormattableParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }

      detailsSection2Image {
        fluid(
          maxWidth: 800
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "800"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      detailsSectionImage2 {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      detailsSectionImage3 {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "1:1"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      detailsSectionText1Components {
        ... on DatoCmsParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      detailsSectionText2FooterNode {
        childMarkdownRemark {
          html
        }
      }
      detailsSectionText2Components {
        ... on DatoCmsParagraph {
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      heroSectionBackgroundImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      metaImage: heroSectionBackgroundImage {
        fixed(
          width: 1024
          height: 512
          imgixParams: {
            fit: "crop"
            w: "1024"
            h: "512"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          width
          height
          src
        }
      }
      heroSectionHeading
      introSectionLargeImage {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      introSectionSmallImage {
        fluid(
          maxWidth: 600
          imgixParams: { fm: "jpg", auto: "compress", w: "600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      introSectionText
      quotesSectionBackgroundImage {
        fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      quote
    }
  }
`
