import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Img from 'gatsby-image'

import theme from 'constants/theme'
import breakpoints from 'helpers/breakpoints'

import Logo from 'images/logo.svg'
import Heading from 'components/atoms/headings/Heading'
import SectionWaveBg from 'components/atoms/layout/SectionWaveBg'
import Divider from 'components/atoms/dividers/HorizontalDivider'
import Flex from 'components/atoms/Flex'
import ResponsivePlx from 'components/atoms/plx/ResponsivePlx'
import TextPlx from 'components/atoms/plx/TextPlx'

const Section = styled(SectionWaveBg)`
  position: relative;
  overflow: visible;
  z-index: 100;
`

const LogoIcon = styled(Logo)`
  width: 138px;

  .text {
    display: none;
  }

  .icon {
    path {
      fill: ${props => props.theme.colors.primary} !important;
    }
  }
`

const LargeImg = styled(Img)`
  > div {
    padding-bottom: 80% !important;
  }
`
const SmallImg = styled(Img)`

  ${breakpoints.lessThan('md')`
    margin-left: -50px;
    transform: translateY(50%);

    > div {
      padding-bottom: 75% !important;
    }      
    `}
  ${breakpoints.greaterThan('md')`
    margin-left: -50px;

    > div {
      padding-bottom: 80% !important;
    }       
    `}
  ${breakpoints.greaterThan('lg')`
    margin-left: -100px;

    > div {
      padding-bottom: 75% !important;
    }      
    `}
  ${breakpoints.greaterThan('xl')`
    margin-left: -200px;

    > div {
      padding-bottom: 80% !important;
    }    
    `}
`

const Intro = ({ data }) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 100,
          endValue: 50,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ]

  return (
    <Section pt={{ xs: 6, xl: 10 }}>
      <Container maxWidth="xl">
        <Flex flexDirection="column" center textAlign="center">
          <TextPlx>
            <LogoIcon />
          </TextPlx>
          <TextPlx>
            <Heading as="p" headingSize="h4" lineHeight="1.5" color="white">
              {data.introSectionText}
            </Heading>
          </TextPlx>
          <TextPlx>
            <Divider
              width="20px"
              bg={theme.colors.primary}
              mt={{ xs: 3 }}
              mb={{ xs: 6, xl: 8 }}
            />
          </TextPlx>
        </Flex>
      </Container>
      <Grid container spacing={0}>
        <Grid item xs={7} xl={8}>
          <LargeImg fluid={data.introSectionLargeImage.fluid} />
        </Grid>
        <Grid item xs={5} xl={4}>
          <ResponsivePlx breakpoint="md" parallaxData={parallaxData}>
            <SmallImg fluid={data.introSectionSmallImage.fluid} />
          </ResponsivePlx>
        </Grid>
      </Grid>
    </Section>
  )
}

Intro.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Intro)
