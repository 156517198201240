import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Section from 'components/atoms/layout/Section'
import Flex from 'components/atoms/Flex'

import Div100vh from 'components/atoms/layout/Div100vh'
import BackgroundPlx from 'components/atoms/plx/BackgroundPlx'
import HeroSwiper from 'components/molecules/swipers/HeroSwiper'
import Explore from 'components/molecules/hero/Explore'
import BackgroundImageContainer from 'components/atoms/media/BackgroundImageContainer'

const StyledSection = styled(Section)`
  background: ${(props) => props.theme.colors.darkBg};
`

const StyledFlex = styled(Flex)`
  position: relative;
  text-align: center;
  height: 100%;
  color: white;
`

const ContentContainer = styled(Div100vh)`
  position: relative;
  color: white;
  text-align: center;
  z-index: 1000;
`

const SlideShowHero = ({ images, explore, children }) => {
  const parallaxData = [
    {
      start: '0',
      end: '100%',
      properties: [
        {
          startValue: 0,
          endValue: 200,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ]

  let content = explore ? (
    <Flex flexDirection="column" full>
      <Flex flexGrow={1} center fullWidth>
        {children}
      </Flex>

      <Explore>{explore}</Explore>
    </Flex>
  ) : (
    <StyledFlex fullWidth center>
      {children}
    </StyledFlex>
  )

  return (
    <StyledSection>
      <BackgroundPlx parallaxData={parallaxData} breakpoint="lg">
        {images.length ? (
          <HeroSwiper>
            {images.map((image) => {
              return (
                <div key={image.originalId}>
                  <BackgroundImageContainer>
                    <Img fluid={image.fluid} />
                  </BackgroundImageContainer>
                </div>
              )
            })}
          </HeroSwiper>
        ) : (
          <BackgroundImageContainer>
            <Img fluid={images.fluid} />
          </BackgroundImageContainer>
        )}
      </BackgroundPlx>
      <ContentContainer>{content}</ContentContainer>
    </StyledSection>
  )
}

SlideShowHero.propTypes = {
  images: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node.isRequired,
  explore: PropTypes.node,
}

export default memo(SlideShowHero)
