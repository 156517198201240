import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'

import Box from 'components/atoms/Box'
import Section from 'components/atoms/layout/Section'
import SectionHeading from 'components/atoms/headings/SectionHeading'
import ExternalButtonLink from 'components/atoms/buttons/ExternalButtonLink'
import SectionJoin from 'components/atoms/dividers/SectionJoin'
import TextPlx from 'components/atoms/plx/TextPlx'

const StyledContainer = styled(Container)`
  text-align: center;
`

const DownloadSection = ({ heading, text, buttonText, fileUrl }) => {
  return (
    <Section
      bg="white"
      py={{
        xs: 10,
        //   sm: 5,
        md: 14,
        // lg: 8,
        xl: 17,
      }}
    >
      <SectionJoin top />
      <StyledContainer maxWidth="md">
        <TextPlx>
          <SectionHeading containerProps={{ mb: 3 }}>{heading}</SectionHeading>
        </TextPlx>
        <TextPlx>
          <Box
            fontSize={{ xs: '1.8rem', lg: '2rem' }}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </TextPlx>
        <TextPlx>
          <ExternalButtonLink
            mt={{ xs: 3 }}
            href={fileUrl}
            className="double-border sharp dark-bg xl"
          >
            {buttonText}
          </ExternalButtonLink>
        </TextPlx>
      </StyledContainer>
    </Section>
  )
}

DownloadSection.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
}

export default DownloadSection
