import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Img from 'gatsby-image'

import Div100vh from 'components/atoms/layout/Div100vh'
import Section from 'components/atoms/layout/Section'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import Heading from 'components/atoms/headings/Heading'
import FaderSwiper from 'components/molecules/swipers/FadeSwiper'
import SectionHeading from 'components/atoms/headings/SectionHeading'
import RoundFrame from 'components/atoms/media/RoundFrame'
import SmallText from 'components/atoms/text/SmallText'
import BackgroundPlx from 'components/atoms/plx/BackgroundPlx'
import BackgroundImageContainer from 'components/atoms/media/BackgroundImageContainer'
import TextPlx from 'components/atoms/plx/TextPlx'

const QuoteContainer = styled.div`
  color: white;
  text-align: center;
`

const Quote = ({ bgImage, quote }) => {
  const plxBg = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: -40,
          endValue: 40,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ]

  return (
    <Section overflowYHidden overflowXHidden>
      <BackgroundPlx parallaxData={plxBg} breakpoint="lg">
        <BackgroundImageContainer>
          <Img fluid={bgImage.fluid} />
        </BackgroundImageContainer>
      </BackgroundPlx>
      <Div100vh>
        <Flex full center className="foreground" py={6}>
          <Container maxWidth="xl">
            <TextPlx>
              <QuoteContainer key={`quote-${quote.originalId}`}>
                <Heading as="p" headingSize="h2" color="white">
                  &quot;{quote}&quot;
                </Heading>
              </QuoteContainer>
            </TextPlx>
          </Container>
        </Flex>
      </Div100vh>
    </Section>
  )
}

Quote.propTypes = {
  bgImage: PropTypes.object.isRequired,
  quote: PropTypes.string.isRequired,
}

export default memo(Quote)
